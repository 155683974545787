import { adminInvoiceDetailTypes } from "../../ActionTypes/adminInvoiceDetailTypes";
import { FetchAdminInvoiceDetailRequest, FetchAdminInvoiceDetailSuccessPayload, FetchAdminInvoiceDetailSuccess, FetchAdminInvoiceDetailFailurePayload, FetchAdminInvoiceDetailFailure } from "../../types/type";

export const fetchAdminInvoiceDetailRequest = (id: any): FetchAdminInvoiceDetailRequest => ({
  type: adminInvoiceDetailTypes.FETCH_ADMIN_INVOICE_DETAIL_REQUEST,
  id
});

export const fetchAdminInvoiceDetailSuccess = (
  payload: FetchAdminInvoiceDetailSuccessPayload
): FetchAdminInvoiceDetailSuccess => ({
  type: adminInvoiceDetailTypes.FETCH_ADMIN_INVOICE_DETAIL_SUCCESS,
  payload
});

export const fetchAdminInvoiceDetailFailure = (
  payload: FetchAdminInvoiceDetailFailurePayload
): FetchAdminInvoiceDetailFailure => ({
  type: adminInvoiceDetailTypes.FETCH_ADMIN_INVOICE_DETAIL_FAILURE,
  payload
});