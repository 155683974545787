import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminInvoiceDetailTypes } from "../../ActionTypes/adminInvoiceDetailTypes";
import { fetchAdminInvoiceDetailSuccess, fetchAdminInvoiceDetailFailure } from "../../actions/adminInvoiceDetailActions/adminInvoiceDetailActions";

const getAdminInvoiceDetail = (id: any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/invoice_detail/' + id , config); 
}


function* fetchAdminInvoiceDetailSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminInvoiceDetail, action.id);
    yield put(
      fetchAdminInvoiceDetailSuccess({
        invoiceDetail: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminInvoiceDetailFailure({
        error: e.message
      })
    );
  }
}

function* AdminInvoiceDetailSaga() {
  yield takeLatest(adminInvoiceDetailTypes.FETCH_ADMIN_INVOICE_DETAIL_REQUEST, fetchAdminInvoiceDetailSaga);
}
export default AdminInvoiceDetailSaga;