import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AdminNav from '../adminTop/AdminNav';
import { RootState } from "../../redax/reducers/rootReducer";
import dayjs from 'dayjs';
import Loader from '../loader/loader';
import { fetchAdminInvoiceDetailRequest } from '../../redax/actions/adminInvoiceDetailActions/adminInvoiceDetailActions';
import { fetchAdminInvoiceDetailPostRequest } from '../../redax/actions/adminInvoiceDetailPostActions/adminInvoiceDetailPostActions';

const AdminInvoiceDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();    
    const { id } = useParams();
    const adminInvoiceDetail = useSelector((state: RootState) => state.adminInvoiceDetail);
    const adminInvoiceDetailPost = useSelector((state: RootState) => state.adminInvoiceDetailPost);
    const [loadScreen, setLoadScreen] = useState(true);
    const [planName, setPlanName] = useState('');
    const [basePrice, setBasePrice] = useState('');
    const [totalPrice, setTotalPrice] = useState('');
    const [scoutAgency, setScoutAgency] = useState('');
    const [minGuarantee, setMinGuarantee] = useState('');

    const [options, setOptions] = useState<any>([]);


    
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthAdminToken')) {
          navigate('/admin/login');
        } else {
          dispatch(fetchAdminInvoiceDetailRequest(id));
        //   setFirstLoad(true);
        }    
      }, [dispatch]);


      useEffect(() => {
        if (adminInvoiceDetail.pending) {
            setLoadScreen(false);
            setPlanName(adminInvoiceDetail.invoiceDetail.plan_name);
            setBasePrice(adminInvoiceDetail.invoiceDetail.base_price);
            setTotalPrice(adminInvoiceDetail.invoiceDetail.total_price);
            setOptions(adminInvoiceDetail.invoiceDetail.options);
            
        }  
      }, [adminInvoiceDetail]);


      useEffect(() => {
        if (adminInvoiceDetailPost.pending) {
            setLoadScreen(false);
            
        }  
      }, [adminInvoiceDetailPost]);

      useEffect(() => {
        if(options.length > 0 ){
            for(let i = 0; i < options.length; i++){
                if(options[i].name == "スカウト代行"){
                    let str = options[i].name + '50件' + options[i].price.toLocaleString() + '円/月 (税別)';
                    setScoutAgency(str)
                } else if (options[i].name == "最低保証"){
                    let str1 = options[i].name + options[i].price.toLocaleString() + '円/月 (税別)';
                    setMinGuarantee(str1);
                }
            }
        }
      }, [options]);

      function companyEdit () {
        const data =  {
            invoice_id: id,
            company_plan: planName,
            // contract: '',
            base_price: basePrice,
            option_price: adminInvoiceDetail.invoiceDetail.option_price,
            tax_rate: adminInvoiceDetail.invoiceDetail.tax_rate,
            // company_address: '',
            // company_phone: '',
            payment_deadline: adminInvoiceDetail.invoiceDetail.deadline,
            discount: adminInvoiceDetail.invoiceDetail.discount,
            // receipt_address: adminInvoiceDetail.invoiceDetail.receipt_address,
            // receipt_address2: adminInvoiceDetail.invoiceDetail.receipt_address2,
            options : options,
        }
        // setLoadScreen(true);
        dispatch(fetchAdminInvoiceDetailPostRequest(data));
      }



  return (
    <main id='adminHome'>
        <AdminNav/>
        {loadScreen ? <Loader></Loader> : <></>}
        <section className='invoiceDetail'>
            <div>

                <div className="table-container">
                    <h1 className='titleInvoice'>現在の金額内訳</h1>

                    <table className="invoice-detail-table">
                        <tbody>
                        <tr>
                            <td>ご利用内容</td>
                            <td className='tableColor'>{planName}</td>
                        </tr>
                        <tr>
                            <td>基本料金</td>
                            <td className='tableColor'>{basePrice.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>スカウト代行</td>
                            <td className='tableColor'>{scoutAgency}</td>
                        </tr>
                        <tr>
                            <td>エントリー最低保証</td>
                            <td className='tableColor'>{minGuarantee}</td>
                        </tr>
                        <tr>
                            <td>合計金額</td>
                            <td className='tableColor'>{totalPrice.toLocaleString()}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className='dFlex'>
                        <div style={{marginRight:'20px'}}>
                            <label>項目名  <input  type="text" name="name1" /> </label>
                        </div>
                        <div>
                            <label>追加勘定(割引の場合の数値を入れて下さい) <input type="number" name="dis1"/> </label>
                        </div>
                    </div>
                    <div className='dFlex'>
                        <div style={{marginRight:'20px'}}>
                            <label>項目名  <input  type="text" name="name2" /> </label>
                        </div>
                        <div>
                            <label>追加勘定(割引の場合の数値を入れて下さい) <input type="number" name="dis2"/> </label>
                        </div>
                    </div>
                    <h1 className='titleInvoice'>変更後の合計金額</h1>
                    <table className="invoice-detail-table">
                        <tbody>
                        <tr>
                            <td>変更後の合計金額</td>
                            <td className='tableColor'></td>
                        </tr>
                        </tbody>
                    </table>
                    <div>
                        <button className='invoiceDtlBtn'  onClick={()=>companyEdit()}>変更</button>
                    </div>
                </div>
            </div>
        </section>
    </main>
  )
}

export default AdminInvoiceDetail
