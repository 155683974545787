import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminApplicantsDeleteTypes } from "../../ActionTypes/adminApplicantsDeleteTypes";
import { fetchAdminApplicantsDeleteSuccess, fetchAdminApplicantsDeleteFailure } from "../../actions/adminApplicantsDeleteActions/adminApplicantsDeleteActions";


const getAdminApplicantsDelete = (id:any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
    return axios.post(API_BASE_ADDRESS + '/admin/applicants/hard_delete' , {id: id}, config); 
 
}


function* fetchAdminApplicantsDeleteSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminApplicantsDelete,action.id);
    yield put(
      fetchAdminApplicantsDeleteSuccess({
        applicants: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminApplicantsDeleteFailure({
        error: e.message
      })
    );
  }
}

function* AdminApplicantsDeleteSaga() {
  yield takeLatest(adminApplicantsDeleteTypes.FETCH_ADMIN_APPLICANTS_DELETE_REQUEST, fetchAdminApplicantsDeleteSaga);
}
export default AdminApplicantsDeleteSaga;