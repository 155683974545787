import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminApplicationsStatusPostTypes } from "../../ActionTypes/adminApplicationsStatusPostTypes";
import { fetchAdminApplicationsStatusPostFailure, fetchAdminApplicationsStatusPostSuccess } from "../../actions/adminApplicationsStatusPostActions/adminApplicationsStatusPostActions";


const getAdminApplicationsStatusPost = (status_id: any, id:any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
    return axios.post(API_BASE_ADDRESS + '/admin/applications/status_update' , {id: id,status_id: status_id}, config); 
 
}


function* fetchAdminApplicationsStatusPostSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminApplicationsStatusPost, action.status_id, action.id);
    yield put(
      fetchAdminApplicationsStatusPostSuccess({
        status: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminApplicationsStatusPostFailure({
        error: e.message
      })
    );
  }
}

function* AdminApplicationsStatusPostSaga() {
  yield takeLatest(adminApplicationsStatusPostTypes.FETCH_ADMIN_APPLICATIONS_STATUS_POST_REQUEST, fetchAdminApplicationsStatusPostSaga);
}
export default AdminApplicationsStatusPostSaga;