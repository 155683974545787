import { adminApplicationsStatusPostTypes } from "../../ActionTypes/adminApplicationsStatusPostTypes";
import { AdminApplicationsStatusPostState, AdminApplicationsStatusPostActions } from "../../types/type";

const initialState: AdminApplicationsStatusPostState = {
  pending: false,
  status: [],
  error: null,
};

export default (state = initialState, action: AdminApplicationsStatusPostActions,) => {
  switch (action.type) {
    case adminApplicationsStatusPostTypes.FETCH_ADMIN_APPLICATIONS_STATUS_POST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminApplicationsStatusPostTypes.FETCH_ADMIN_APPLICATIONS_STATUS_POST_SUCCESS:
      return {
        ...state,
        pending: true,
        status: action.payload.status,
        error: null
      };
    case adminApplicationsStatusPostTypes.FETCH_ADMIN_APPLICATIONS_STATUS_POST_FAILURE:
      return {
        ...state,
        pending: false,
        status: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
