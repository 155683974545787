import { adminApplicationsStatusPostTypes } from "../../ActionTypes/adminApplicationsStatusPostTypes";
import { FetchAdminApplicationsStatusPostRequest, FetchAdminApplicationsStatusPostSuccessPayload, FetchAdminApplicationsStatusPostSuccess, FetchAdminApplicationsStatusPostFailurePayload, FetchAdminApplicationsStatusPostFailure } from "../../types/type";

export const fetchAdminApplicationsStatusPostRequest = (status_id:any, id:any): FetchAdminApplicationsStatusPostRequest => ({
  type: adminApplicationsStatusPostTypes.FETCH_ADMIN_APPLICATIONS_STATUS_POST_REQUEST,
  status_id,
  id,
});

export const fetchAdminApplicationsStatusPostSuccess = (
  payload: FetchAdminApplicationsStatusPostSuccessPayload
): FetchAdminApplicationsStatusPostSuccess => ({
  type: adminApplicationsStatusPostTypes.FETCH_ADMIN_APPLICATIONS_STATUS_POST_SUCCESS,
  payload
});

export const fetchAdminApplicationsStatusPostFailure = (
  payload: FetchAdminApplicationsStatusPostFailurePayload
): FetchAdminApplicationsStatusPostFailure => ({
  type: adminApplicationsStatusPostTypes.FETCH_ADMIN_APPLICATIONS_STATUS_POST_FAILURE,
  payload
});