import { adminApplicantsDeleteTypes } from "../../ActionTypes/adminApplicantsDeleteTypes";
import { FetchAdminApplicantsDeleteRequest, FetchAdminApplicantsDeleteSuccessPayload, FetchAdminApplicantsDeleteSuccess, FetchAdminApplicantsDeleteFailurePayload, FetchAdminApplicantsDeleteFailure } from "../../types/type";

export const fetchAdminApplicantsDeleteRequest = (id:any): FetchAdminApplicantsDeleteRequest => ({
  type: adminApplicantsDeleteTypes.FETCH_ADMIN_APPLICANTS_DELETE_REQUEST,
  id,
});

export const fetchAdminApplicantsDeleteSuccess = (
  payload: FetchAdminApplicantsDeleteSuccessPayload
): FetchAdminApplicantsDeleteSuccess => ({
  type: adminApplicantsDeleteTypes.FETCH_ADMIN_APPLICANTS_DELETE_SUCCESS,
  payload
});

export const fetchAdminApplicantsDeleteFailure = (
  payload: FetchAdminApplicantsDeleteFailurePayload
): FetchAdminApplicantsDeleteFailure => ({
  type: adminApplicantsDeleteTypes.FETCH_ADMIN_APPLICANTS_DELETE_FAILURE,
  payload
});