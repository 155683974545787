import { adminInvoiceDetailTypes } from "../../ActionTypes/adminInvoiceDetailTypes";
import { AdminInvoiceDetailState, AdminInvoiceDetailActions } from "../../types/type";



const initialState: AdminInvoiceDetailState = {
  pending: false,
  invoiceDetail: [],
  error: null,
};

export default (state = initialState, action: AdminInvoiceDetailActions,) => {
  switch (action.type) {
    case adminInvoiceDetailTypes.FETCH_ADMIN_INVOICE_DETAIL_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminInvoiceDetailTypes.FETCH_ADMIN_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        pending: true,
        invoiceDetail: action.payload.invoiceDetail,
        error: null
      };
    case adminInvoiceDetailTypes.FETCH_ADMIN_INVOICE_DETAIL_FAILURE:
      return {
        ...state,
        pending: false,
        invoiceDetail: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
