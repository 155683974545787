import { adminApplicationsListTypes } from "../../ActionTypes/adminApplicationsListTypes";
import { AdminApplicationsListActions, AdminApplicationsListState, AdminRecruitmentListActions, AdminRecruitmentListState } from "../../types/type";

const initialState: AdminApplicationsListState = {
  pending: false,
  applications: [],
  pagenation: {},
  status_list: [],
  error: null,
};

export default (state = initialState, action: AdminApplicationsListActions,) => {
  switch (action.type) {
    case adminApplicationsListTypes.FETCH_ADMIN_APPLICATIONS_LIST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminApplicationsListTypes.FETCH_ADMIN_APPLICATIONS_LIST_SUCCESS:
      return {
        ...state,
        pending: true,
        applications: action.payload.applications,
        pagenation: action.payload.pagenation,
        status_list: action.payload.status_list,
        error: null
      };
    case adminApplicationsListTypes.FETCH_ADMIN_APPLICATIONS_LIST_FAILURE:
      return {
        ...state,
        pending: false,
        applications: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
