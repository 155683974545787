import { adminApplicantsDeleteTypes } from "../../ActionTypes/adminApplicantsDeleteTypes";
import { AdminApplicantsDeleteState, AdminApplicantsDeleteActions } from "../../types/type";

const initialState: AdminApplicantsDeleteState = {
  pending: false,
  applicants: [],
  error: null,
};

export default (state = initialState, action: AdminApplicantsDeleteActions,) => {
  switch (action.type) {
    case adminApplicantsDeleteTypes.FETCH_ADMIN_APPLICANTS_DELETE_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminApplicantsDeleteTypes.FETCH_ADMIN_APPLICANTS_DELETE_SUCCESS:
      return {
        ...state,
        pending: true,
        applicants: action.payload.applicants,
        error: null
      };
    case adminApplicantsDeleteTypes.FETCH_ADMIN_APPLICANTS_DELETE_FAILURE:
      return {
        ...state,
        pending: false,
        applicants: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
