import { adminInvoiceDetailPostTypes } from "../../ActionTypes/adminInvoiceDetailPostTypes";
import { AdminInvoiceDetailPostActions, AdminInvoiceDetailPostState } from "../../types/type";



const initialState: AdminInvoiceDetailPostState = {
  pending: false,
  invoice: [],
  error: null,
};

export default (state = initialState, action: AdminInvoiceDetailPostActions,) => {
  switch (action.type) {
    case adminInvoiceDetailPostTypes.FETCH_ADMIN_INVOICE_DETAIL_POST_REQUEST:
      return {
        ...state,
        pending: false
      };
      case adminInvoiceDetailPostTypes.FETCH_ADMIN_INVOICE_DETAIL_POST_SUCCESS:
      return {
        ...state,
        pending: true,
        invoice: action.payload.invoice,
        error: null
      };
    case adminInvoiceDetailPostTypes.FETCH_ADMIN_INVOICE_DETAIL_POST_FAILURE:
      return {
        ...state,
        pending: false,
        invoice: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
