import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { adminInvoiceDetailPostTypes } from "../../ActionTypes/adminInvoiceDetailPostTypes";
import { fetchAdminInvoiceDetailPostFailure, fetchAdminInvoiceDetailPostSuccess } from "../../actions/adminInvoiceDetailPostActions/adminInvoiceDetailPostActions";


const getAdminInvoiceDetailPost = (body: any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  // if(method == "POST"){
    return axios.post(API_BASE_ADDRESS + '/admin/invoice_update' , body, config); 
  // } else if (method =="PUT") {
  //   return axios.put(API_BASE_ADDRESS + '/admin/applicants/'+ id , {applicant: body}, config); 
  // } else {
  //   return axios.delete(API_BASE_ADDRESS + '/admin/applicants/'+ id , config);
  // }
 
}


function* fetchAdminInvoiceDetailPostSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminInvoiceDetailPost, action.body);
    yield put(
      fetchAdminInvoiceDetailPostSuccess({
        invoice: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminInvoiceDetailPostFailure({
        error: e.message
      })
    );
  }
}

function* AdminInvoiceDetailPostSaga() {
  yield takeLatest(adminInvoiceDetailPostTypes.FETCH_ADMIN_INVOICE_DETAIL_POST_REQUEST, fetchAdminInvoiceDetailPostSaga);
}
export default AdminInvoiceDetailPostSaga;