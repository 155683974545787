import { adminInvoiceDetailPostTypes } from "../../ActionTypes/adminInvoiceDetailPostTypes";
import { FetchAdminInvoiceDetailPostRequest, FetchAdminInvoiceDetailPostSuccessPayload, FetchAdminInvoiceDetailPostSuccess, FetchAdminInvoiceDetailPostFailurePayload, FetchAdminInvoiceDetailPostFailure } from "../../types/type";

export const fetchAdminInvoiceDetailPostRequest = (body: any): FetchAdminInvoiceDetailPostRequest => ({
  type: adminInvoiceDetailPostTypes.FETCH_ADMIN_INVOICE_DETAIL_POST_REQUEST,
  body,
  // method,
  // id,
});

export const fetchAdminInvoiceDetailPostSuccess = (
  payload: FetchAdminInvoiceDetailPostSuccessPayload
): FetchAdminInvoiceDetailPostSuccess => ({
  type: adminInvoiceDetailPostTypes.FETCH_ADMIN_INVOICE_DETAIL_POST_SUCCESS,
  payload
});

export const fetchAdminInvoiceDetailPostFailure = (
  payload: FetchAdminInvoiceDetailPostFailurePayload
): FetchAdminInvoiceDetailPostFailure => ({
  type: adminInvoiceDetailPostTypes.FETCH_ADMIN_INVOICE_DETAIL_POST_FAILURE,
  payload
});